<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
<!--							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />-->
<!--							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedMietvertrag || !selectedMietvertrag.length" />-->
						</div>
					</template>


					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="auftraege" v-model:selection="selectedAuftrag" dataKey="ID" :paginator="true" sort-field="eingang" :sort-order="-1" :rows="10" filter-display="menu"  :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} Aufträge" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Aufträge</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

<!--					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
					<Column field="ID" header="ID" :sortable="true" :filterMatchModeOptions="matchModes">>
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
            <template #filter>
              <InputText type="text" v-model="filters['ID'].value" @input="onUpdateFilter($event, 'ID')"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
					</Column>
					<Column field="unternehmen_name" header="Auftraggeber" :sortable="true" :filterMatchModeOptions="matchModes">
						<template #body="slotProps">
							<span class="p-column-title">Auftraggeber</span>
							{{slotProps.data.unternehmen_name}}
						</template>
            <template #filter>
              <InputText type="text" v-model="filters['unternehmen_name'].value" @input="onUpdateFilter($event, 'unternehmen_name')"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
					</Column>
          <Column field="AUFTRAG" header="Auftrag" :sortable="true" :filterMatchModeOptions="matchModes">>
            <template #body="slotProps">
              <span class="p-column-title">Auftrag</span>
              {{slotProps.data.AUFTRAG}}
            </template>
            <template #filter>
              <InputText type="text" v-model="filters['AUFTRAG'].value" @input="onUpdateFilter($event, 'AUFTRAG')"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="erfasser" header="Erfasser" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">Erfasser</span>
              {{slotProps.data.erfasser}}
            </template>
            <template #filter>
              <InputText type="text" v-model="filters['erfasser'].value" @input="onUpdateFilter($event, 'erfasser')"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="eingang" header="Eingang" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="{data}">
              {{formatDate(data.eingang)}}
            </template>
            <template #filter>
              <InputText type="text" v-model="filters['eingang'].value" @input="onUpdateFilter($event, 'eingang')"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="abgeschlossen" header="Abschluss" :sortable="true" :filterMatchModeOptions="matchModes">>
            <template #body="{data}">
              {{formatDate(data.abgeschlossen)}}
            </template>
            <template #filter>
              <InputText type="text" v-model="filters['abgeschlossen'].value" @input="onUpdateFilter($event, 'abgeschlossen')"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="STATUS" header="Status" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="{data}">
              <span class="p-column-title">Status</span>
<!--              {{slotProps.data.STATUS}}-->
              <Tag :value="data.STATUS" :severity="getSeverity(data.STATUS)" />
            </template>
            <template #filter>
<!--              <InputText type="text" v-model="filters['STATUS'].value" @input="onUpdateFilter($event, 'STATUS')"/>-->
              <Dropdown v-model="filters['STATUS'].value" :options="statuses" @input="onUpdateFilter($event, 'STATUS')">
                <template #option="slotProps">
                  <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                </template>
              </Dropdown>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="FK_ID_PRODUKT" header="Produkt" :sortable="true" :filterMatchModeOptions="matchModes">
            <template #body="slotProps">
              <span class="p-column-title">Produkt</span>
              {{slotProps.data.FK_ID_PRODUKT}}
            </template>
            <template #filter>
              <InputText type="number" v-model="filters['FK_ID_PRODUKT'].value" @input="onUpdateFilter($event, 'FK_ID_PRODUKT')"/>
            </template>
            <template #filterclear></template>
            <template #filterapply></template>
          </Column>
          <Column field="Dashboard" header="Dashboard">
            <template #body="slotProps">
              <span class="p-column-title">Dashboard</span>
              <Button icon="pi pi-link"  @click="onRowSelect(slotProps.data)" />
            </template>
          </Column>
					<Column>
						<template #body="slotProps">
              <div v-show="isVisible">
                <ReperaturenTable  ref="dialog" @reperaturSaved="fetchReperatur"/>
              </div>
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="openReperaturDialog(slotProps.data)" />
<!--							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteMietvetrag(slotProps.data)" />-->
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="mietvetragDialog" class:style="{width: '450px'}" header="Mietvertrag anpassen" :modal="true" class="p-fluid">
          <div class="field">
            <label for="TAGESSATZ">Tagessatz</label>
            <InputText id="name" v-model.trim="mietvetragObj.TAGESSATZ" required="true" autofocus :class="{'p-invalid': submitted && !mietvetragObj.TAGESSATZ}" />
          </div>
					<div class="field">
						<label for="MIETBEGINN">Mietbeginn</label>
            <Calendar inputId="mietbeginn" v-model="mietvetragObj.MIETBEGINN"  show-time="false"></Calendar>
					</div>
          <div class="field">
            <label for="MIETENDE">Mietende</label>
            <Calendar inputId="mietende" v-model="mietvetragObj.MIETENDE" show-time="false"></Calendar>
          </div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveMietvetrag" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMietvetragDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="mietvetragObj"><b>Mietvertrag </b><b>{{mietvetragObj.ID}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteMietvetragDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteMietvetrag" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMietvetragsDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="mietvetragObj">Ausgewählte mietvetrag wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteMietvetragsDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteselectedMietvertrag" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";
import moment from "moment/moment";
import Calendar from "primevue/calendar";
import store from "@/store";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import ReperaturenTable from "@/components/ReperaturenTable.vue";

export default {
  components: {ReperaturenTable ,InputText, DataTable, Calendar},
	data() {
		return {
      auftraege: null,
      mietvetragDialog: false,
			deleteMietvetragDialog: false,
			deleteMietvetragsDialog: false,
      neu: false,
      auftragObj: {},
			selectedAuftrag: null,
			filters: {},
			submitted: false,
      isVisible: false,
      matchModes: [
        {label: 'Suchbegriff', value: FilterMatchMode.CONTAINS},
      ],
      statuses: ['Abgeschlossen', 'In Bearbeitung', 'Offen']
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    this.auftraege = await this.dataService.getAuftraege(store.state.token_decoded.id_unternehmen);
    // console.log(this.auftraege.filter(auftrag => auftrag.STATUS === 'Abgeschlossen'));
  },
	methods: {
		openNew() {
			this.mietvetragObj = {};
			this.submitted = false;
			this.mietvetragDialog = true;
      this.neu = true;
		},
    formatDate(dateString) {
      if (!dateString) {
        return ''; // Gebe einen leeren String zurück, wenn der Wert null oder leer ist
      }

      return moment(dateString).isValid() ? moment(dateString).format('DD.MM.YYYY') : '';
    },

    onRowSelect(event){
      // console.log(event.ID)
      this.$router.push(`/produktdashboard/${event.FK_ID_PRODUKT}`);
    },
    onUpdateFilter(event, filterName) {
      this.$store.commit('updateAuftragFilters', { filterName, value: event.target.value });
    },
		hideDialog() {
			this.mietvetragDialog = false;
			this.submitted = false;
		},
    async saveMietvetrag(){
      this.submitted = true;
      if(this.neu){
          await this.dataService.saveMietvetrag(this.mietvetragObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag erstellt', life: 3000});
          setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);
          this.mietvetragDialog = false;
          this.mietvetragObj = {};

      } else {
          await this.dataService.updateMietvertrag(this.mietvetragObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag aktualisiert', life: 3000});
          setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);
          this.mietvetragDialog = false;
          this.mietvetragObj = {};

      }

    },
    editMietvetrag(mietvetrag) {
      this.mietvetragObj = {...mietvetrag};
      this.mietvetragDialog = true;
      this.neu = false;
    },
    confirmDeleteMietvetrag(mietvetrag) {
      this.mietvetragObj = mietvetrag;
      this.deleteMietvetragDialog = true;
    },
    async fetchReperatur() {
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Auftrag aktualisiert', life: 3000});
      setTimeout(async()=> {this.auftraege = await this.dataService.getAuftraege(store.state.token_decoded.id_unternehmen);}, 100);

      // this.reperaturen = await this.dataService.getReperaturByProuktId(this.$route.params.id);
    },
    async deleteMietvetrag() {
      let data = [];
      data.push(this.mietvetragObj);
      await this.dataService.deleteMietvertrag(data);

      this.deleteMietvetragDialog = false;
      this.mietvetragObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag gelöscht', life: 3000});
      setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteMietvetragsDialog = true;
		},
    async deleteselectedMietvertrag() {
      this.deleteMietvetragsDialog = false;
      await this.dataService.deleteMietvertrag(this.selectedMietvertrag);
      this.selectedMietvertrag = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag gelöscht', life: 3000});
      setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);

    },
    async openReperaturDialog(reperatur){
      this.$refs.dialog.editReperatur(reperatur);
    },
		initFilters() {
      this.filters=store.getters.getAuftragFilters;
        },

    getSeverity(status) {
      switch (status) {
        case 'Offen':
          return 'danger';

        case 'Abgeschlossen':
          return 'success';

        case 'In Bearbeitung':
          return 'info';
      }
    }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
